import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import HeroSection from "../components/HeroSection";
import Testimonials11 from "../components/Testimonials11";
import Layout4 from "../components/Layout4";
import Layout353 from "../components/Layout353";
import Cta29 from "../components/Cta29";
import Layout28 from "../components/Layout28";
import Layout207 from "../components/Layout207";
import Contact2 from "../components/Contact2";
import Faq1 from "../components/Faq1";
import Header62 from "../components/Header62";
import Team18 from "../components/Team18";
import Layout1 from "../components/Layout1";
import Logos6 from "../components/Logos6";
import Cta17 from "../components/Cta17";
import Header50 from "../components/Header50";
import Layout354 from "../components/Layout354";
import Header65 from "../components/Header65";
import Contact16 from "../components/Contact16";
import SectionLayout3 from "../components/SectionLayout3";
import Header44 from "../components/Header44";
import Layout195 from "../components/Layout195";
import Blog1 from "../components/Blog1";
import Webinars from "../components/Webinars";
import LayoutNew from "../components/LayoutNew";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import HeroSectionLanding from "../components/hero-section-landing";
import ContactLanding from "../components/contact-landing";
import LayoutLanding from "../components/layout-landing";

const LandingPageTemplate = ({
	data: {
		pageData,
		site,
		posts,
		categories,
		featuredBlog,
		webinars,
		latestWebinar,
	},
}) => {
	const { components } = pageData.pageFieldsNew;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Landing",
				item: {
					url: `${site.siteMetadata.siteUrl}/landing`,
					id: `${site.siteMetadata.siteUrl}/landing`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: pageData.title,
				item: {
					url: `${site.siteMetadata.siteUrl}/landing/${pageData.slug}`,
					id: `${site.siteMetadata.siteUrl}/landing/${pageData.slug}`,
				},
			},
		],
	};

	return (
		<LayoutLanding>
			<GatsbySeo title={pageData.seoFields?.title} language="en" noindex />

			{components.map((component, index) => {
				switch (component.fieldGroupName) {
					case "LandingPage_Pagefieldsnew_Components_HeroSection":
						return <HeroSection key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_HeroSectionLanding":
						return <HeroSectionLanding key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Testimonials11":
						return <Testimonials11 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout4":
						return (
							<Layout4
								noWebinarButton={true}
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Layout353":
						return (
							<Layout353
								noWebinarButton
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Cta29":
						return <Cta29 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout28":
						return <Layout28 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout207":
						return (
							<Layout207
								noWebinarButton
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Contact2":
						return <Contact2 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_ContactLanding":
						return <ContactLanding key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Faq1":
						return <Faq1 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Header62":
						return <Header62 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Team18":
						return <Team18 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout1":
						return (
							<Layout1
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Logos6":
						return <Logos6 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Cta17":
						return (
							<Cta17
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Header50":
						return <Header50 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout354":
						return <Layout354 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Header65":
						return <Header65 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Contact16":
						return <Contact16 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_SectionLayout3":
						return <SectionLayout3 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Header44":
						return <Header44 key={index} data={component} />;
					case "LandingPage_Pagefieldsnew_Components_Layout195":
						return (
							<Layout195
								latestWebinar={latestWebinar.latestWebinarFields.link}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Blog1":
						return (
							<Blog1
								posts={posts}
								featuredBlog={featuredBlog}
								categories={categories}
								key={index}
								data={component}
							/>
						);
					case "LandingPage_Pagefieldsnew_Components_Webinars":
						return (
							<Webinars
								key={index}
								data={component}
								webinars={webinars.nodes}
							/>
						);
					default:
						return null;
				}
			})}
		</LayoutLanding>
	);
};

export default LandingPageTemplate;

export const pageQuery = graphql`
	query LandingPageById($id: String!) {
		latestWebinar: wpLatestWebinar(title: { eq: "Latest Webinar" }) {
			latestWebinarFields {
				link
			}
		}
		pageData: wpLandingPage(id: { eq: $id }) {
			title
			slug
			seoFields {
				fieldGroupName
				localBusinessSchema
				metaDescription
				opengraphDescription
				opengraphTitle
				title
			}
			pageFieldsNew {
				components {
					... on WpLandingPage_Pagefieldsnew_Components_HeroSectionLanding {
						fieldGroupName
						heading
						landingPageFormTitle
						smallHeading
						body
						button1 {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_HeroSection {
						fieldGroupName
						heading
						smallHeading
						body
						button1 {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Testimonials11 {
						fieldGroupName
						heading
						body
						testimonials {
							... on WpTestimonial {
								title
								testimonialsContent {
									testimonialAuthorPosition
									testimonialContent
									testimonialAuthorImage {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout4 {
						fieldGroupName
						heading
						smallHeading
						body
						button1 {
							target
							title
							url
						}

						column1 {
							heading
							body
						}
						column2 {
							heading
							body
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout353 {
						fieldGroupName
						heading
						smallHeading
						body
						steps {
							heading
							items {
								text
							}
						}
						button1 {
							target
							title
							url
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Cta29 {
						fieldGroupName
						heading
						body
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						button1 {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout28 {
						fieldGroupName
						heading
						sections {
							body
							heading
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout207 {
						fieldGroupName
						heading
						smallHeading
						sectionHeading
						body
						hasBackgroundImage
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
						button1 {
							target
							title
							url
						}

						listItems {
							text
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Contact2 {
						fieldGroupName
						heading
						smallHeading
						tab1Text
						tab2Link
						tab2Text
					}
					... on WpLandingPage_Pagefieldsnew_Components_Faq1 {
						fieldGroupName
						heading
						body
						faqs {
							... on WpFaq {
								id
								faqFields {
									question
									answer
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Header62 {
						fieldGroupName
						heading
						smallHeading
						body
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Team18 {
						fieldGroupName
						heading
						smallHeading
						role
						name
						body
						links {
							url
							icon {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout1 {
						fieldGroupName
						heading
						purpleBackground
						webinarButton
						smallHeading
						body
						videoOrImage
						button1 {
							target
							title
							url
						}
						button2 {
							target
							title
							url
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						video {
							mediaItemUrl
							altText
							sourceUrl
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Logos6 {
						fieldGroupName
						logos {
							url
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Cta17 {
						fieldGroupName
						heading
						body
						button1 {
							target
							title
							url
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Header50 {
						fieldGroupName
						heading
						smallHeading
						body
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout354 {
						fieldGroupName
						heading
						smallHeading
						body
						cards {
							heading
							body
							button {
								url
								title
							}
						}
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Header65 {
						fieldGroupName
						heading
						smallHeading
						body
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Contact16 {
						fieldGroupName
						heading
						smallHeading
						body
						items {
							heading
							link {
								url
								title
							}
							icon {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_SectionLayout3 {
						fieldGroupName
						heading
						body
						items {
							heading
							body
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Header44 {
						fieldGroupName
						heading
						smallHeading
						body
						backgroundImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_Layout195 {
						fieldGroupName
						heading
						smallHeading
						body
						button1 {
							target
							title
							url
						}

						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpLandingPage_Pagefieldsnew_Components_ContactLanding {
						fieldGroupName
						heading
						landingPageFormTitle
						smallHeading
					}
					... on WpLandingPage_Pagefieldsnew_Components_Blog1 {
						fieldGroupName
						heading
						smallHeading
						body
					}
				}
			}
		}
		posts: allWpPost(
			filter: { featuredFields: { featuredBlog: { ne: true } } }
		) {
			nodes {
				title
				slug
				excerpt
				blogFields {
					numberOfMinRead
					heading
					smallHeading
					backgroundImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
					featuredImage {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				categories {
					nodes {
						name
					}
				}
				dateGmt(formatString: "MMMM YY")
			}
		}
		categories: allWpCategory(filter: { name: { ne: "Uncategorized" } }) {
			nodes {
				name
			}
		}
		featuredBlog: wpPost(featuredFields: { featuredBlog: { eq: true } }) {
			title
			slug
			excerpt
			blogFields {
				heading
				numberOfMinRead
				smallHeading
				backgroundImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				featuredImage {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			categories {
				nodes {
					name
				}
			}
			dateGmt(formatString: "MMMM YY")
		}
		webinars: allWpPreviousWebinar {
			nodes {
				title
				webinarFields {
					body
					heading
					numberOfMins
					videoLink
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
