import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, Button } from "react-bootstrap";
import SafeHtmlParser from "../components/SafeHtmlParser";
import NavBarHome from "./NavBarHome";
import { BgImage } from "gbimage-bridge";
import Navigation from "./navigation";
import NavLanding from "./nav-landing";
import LandingHeroForm from "./landing-hero-form";

const HeroSectionLanding = ({ data }) => {
	const {
		heading,
		smallHeading,
		body,
		backgroundImage,
		landingPageFormTitle,
		button1,
		button2,
	} = data;
	return (
		<section className="hero-section">
			<BgImage
				alt=""
				className="w-100 position-relative "
				image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
			>
				<NavLanding />
				<div
					style={{ background: "rgba(29, 18, 63, 0.55)", zIndex: -1 }}
					className="w-100 h-100 position-absolute start-0 top-0"
				></div>
				<Container className="py-md-5 py-3 py-xl-9 " style={{ zIndex: 1 }}>
					<Row className="align-items-center justify-content-between pb-4 pb-md-0 g-md-4">
						<Col
							xs={{ span: 12, order: "last" }}
							md={{ span: 6, order: "last" }}
							lg={{ span: 5, order: "last" }}
							xl={{ span: 4, order: "last" }}
						>
							<Row>
								<Col>
									<LandingHeroForm landingPage={landingPageFormTitle} />
								</Col>
							</Row>
						</Col>
						<Col md={6} lg={6}>
							<Row className="mb-4">
								<Col className="text-white">
									<p className="inter-semibold pb-0 mb-2 fs-6">
										{smallHeading}
									</p>
									<div className="bold-yellow mb-3">
										<SafeHtmlParser htmlContent={heading} />
									</div>

									<SafeHtmlParser htmlContent={body} />
								</Col>
							</Row>

							<Row>
								<Col>
									{button1?.title?.length > 0 && (
										<Button
											size="lg"
											className="px-4 py-2 w-100 w-lg-auto inter-medium bg-white text-primary"
											href={button1.url}
											variant="white"
										>
											{button1.title}
										</Button>
									)}
									{button2?.title?.length > 0 && (
										<Button
											size="lg"
											className="ms-lg-3 w-100 w-lg-auto mt-3 inter-medium mt-lg-0"
											href={button2.url}
											variant="outline-white"
										>
											{button2.title}
										</Button>
									)}
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</section>
	);
};

export default HeroSectionLanding;
