import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContactFormLanding({ landingPage }) {
	const [state, handleSubmit] = useForm("mleqoyrd");
	const [gclid, setGclid] = useState(""); // New state to store gclid

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}
	}, []);

	const handleFormSubmit = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("phone");
		const customerName = formData.get("name");

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		handleSubmit(event); // Proceed with Formspree submission
	};

	if (state.succeeded) {
		window.location.href = "/thank-you";
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Row>
				<Col lg={12}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="montserrat-bold">Full name*</Form.Label>
						<Form.Control
							name="name"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="montserrat-bold">Email*</Form.Label>
						<Form.Control
							type="email"
							name="email"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
				<Col md={6}>
					<Form.Group className="mb-3" controlId="phone">
						<Form.Label className="montserrat-bold">Phone number*</Form.Label>
						<Form.Control
							type="tel"
							name="phone"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row className="">
				<Col>
					<Form.Group className="mb-3" controlId="message">
						<Form.Label className="montserrat-bold">Message</Form.Label>
						<Form.Control as="textarea" name="message" rows={4} />
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>
				</Col>
			</Row>

			{/* Hidden field for gclid */}
			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>
			<Form.Group controlId="landingPage" className="d-none">
				<Form.Control type="hidden" name="landingPage" value={landingPage} />
			</Form.Group>

			<Row>
				<Col>
					<div className="mt-3">
						<Form.Check
							inline
							label={
								<span className="inter-regular" style={{ fontSize: "14px" }}>
									I agree to the
									<a
										href={"/privacy"}
										target="_blank"
										rel="noopener noreferrer"
										className="px-1"
									>
										Privacy Notice
									</a>
									of TheLawPartner.coach
								</span>
							}
							name="acceptPolicy"
							type={"checkbox"}
							required
						/>
					</div>
				</Col>
			</Row>

			<Button
				size="lg"
				className="mt-3 w-100 w-md-auto px-4 py-2 float-end inter-medium"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit Form
			</Button>
		</Form>
	);
}

export default ContactFormLanding;
