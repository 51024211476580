import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SafeHtmlParser from "../components/SafeHtmlParser";
import ContactForm from "./contactForm";
import ContactFormLanding from "./contact-form-landing";

const ContactLanding = ({ data }) => {
	const {
		heading,
		smallHeading,
		tab1Text,
		tab2Link,
		tab2Text,
		landingPageFormTitle,
	} = data;

	const [selectedTab, setSelectedTab] = useState("send-message");

	const handleTablClick = (tabName) => {
		setSelectedTab(tabName);
	};

	return (
		<section className="py-5 py-md-8" id="form">
			<Container>
				<Row className="justify-content-center">
					<Col lg={8} className="mb-4  text-center">
						<p className="inter-semibold text-primary">{smallHeading}</p>
						<div className="text-primary">
							<SafeHtmlParser htmlContent={heading} />
						</div>
					</Col>
				</Row>

				<Row className=" justify-content-center">
					<Col lg={8}>
						{tab2Link?.length > 0 && (
							<ul className="talk-tabs mx-auto border mb-4 border-jason d-flex rounded-5 justify-content-center">
								<li
									onClick={() => handleTablClick("send-message")}
									className={`${
										selectedTab === "send-message" ? "active" : ""
									} d-flex justify-content-center align-items-center rounded-start-5`}
								>
									{tab1Text}
								</li>
								<li
									onClick={() => handleTablClick("book-call")}
									className={`${
										selectedTab === "book-call" ? "active" : ""
									} d-flex justify-content-center align-items-center rounded-end-5`}
								>
									{tab2Text}
								</li>
							</ul>
						)}
						<div className="">
							<div
								className={`${selectedTab === "send-message" ? "" : "d-none"}`}
							>
								<ContactFormLanding landingPage={landingPageFormTitle} />
							</div>

							<div className={`${selectedTab === "book-call" ? "" : "d-none"}`}>
								<div id="blab-embed"></div>
								<script src={tab2Link} async></script>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ContactLanding;
