import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SVGIcon from "../images/icon-park-solid-new.svg";
import { Link } from "gatsby";

function LandingHeroForm({ landingPage }) {
	const [state, handleSubmit] = useForm("mleqoyrd");
	const [gclid, setGclid] = useState(""); // New state to store gclid
	const [currentStep, setCurrentStep] = useState(1);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
		acceptPolicy: false,
	});
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}
	}, []);

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const validateStep1 = () => {
		let errors = {};
		if (!formData.name) errors.name = "Full name is required.";
		if (!formData.email) errors.email = "Email is required.";
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const validateStep2 = () => {
		let errors = {};
		if (!formData.phone) errors.phone = "Phone number is required.";
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const validateStep3 = () => {
		let errors = {};
		if (!formData.acceptPolicy)
			errors.acceptPolicy = "You must accept the privacy policy.";
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleNextStep = (e) => {
		e.preventDefault();
		if (currentStep === 1 && validateStep1()) {
			setCurrentStep(2);
		} else if (currentStep === 2 && validateStep2()) {
			setCurrentStep(3);
		}
	};

	const handleFormSubmit = (event) => {
		event.preventDefault();

		if (!validateStep3()) {
			return;
		}

		// Capture form data for dataLayer
		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("phone");
		const customerName = formData.get("name");

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		// Submit form
		handleSubmit(event);
	};

	const hiddenStyle = { display: "none" };
	const errorTextStyle = { color: "red", fontSize: "0.875rem" };

	if (state.succeeded) {
		return (
			<div
				style={{ borderRadius: "24px" }}
				className="w-100 bg-white p-4 rounded-24 text-center"
			>
				<SVGIcon style={{ width: "60px" }} />

				<h5 className="  pt-4 pb-2  inter-bold">Enquiry Submitted</h5>
				<p>
					Your request has been submitted successfully, We will reach out to you
					as soon as possible.
				</p>
				<Button
					as={Link}
					to="/"
					className="px-4 py-2 w-100  inter-medium mt-3 "
					variant="primary"
				>
					Back to home page
				</Button>
			</div>
		);
	}

	return (
		<Form
			style={{ borderRadius: "24px" }}
			className="w-100 bg-white p-4 rounded-24"
			onSubmit={handleFormSubmit}
		>
			<h5 className="text-center inter-bold pb-4 ">
				Book a growth pathway call
			</h5>
			{/* Step 1: Name and Email */}
			<div style={currentStep === 1 ? {} : hiddenStyle}>
				<Row>
					<Col lg={12}>
						<Form.Group className="mb-3" controlId="name">
							<Form.Label className="montserrat-bold inter-bold">
								Full name*
							</Form.Label>
							<Form.Control
								name="name"
								type="text"
								style={{ height: "48px" }}
								value={formData.name}
								onChange={handleInputChange}
								required
							/>
							{formErrors.name && (
								<p style={errorTextStyle}>{formErrors.name}</p>
							)}
						</Form.Group>
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label className="montserrat-bold inter-bold">
								Email*
							</Form.Label>
							<Form.Control
								name="email"
								type="email"
								style={{ height: "48px" }}
								value={formData.email}
								onChange={handleInputChange}
								required
							/>
							{formErrors.email && (
								<p style={errorTextStyle}>{formErrors.email}</p>
							)}
						</Form.Group>
					</Col>
				</Row>

				<Button
					onClick={handleNextStep}
					className="px-4 py-2 w-100  inter-medium mt-3 "
					variant="primary"
				>
					Next Step
				</Button>
			</div>

			{/* Step 2: Phone */}
			<div style={currentStep === 2 ? {} : hiddenStyle}>
				<Row>
					<Col lg={12}>
						<Form.Group className="mb-3" controlId="phone">
							<Form.Label className="montserrat-bold inter-bold">
								Phone number*
							</Form.Label>
							<Form.Control
								type="tel"
								name="phone"
								style={{ height: "48px" }}
								value={formData.phone}
								onChange={handleInputChange}
								required
							/>
							{formErrors.phone && (
								<p style={errorTextStyle}>{formErrors.phone}</p>
							)}
						</Form.Group>
					</Col>
				</Row>

				<Button
					onClick={handleNextStep}
					className="px-4 py-2 w-100  inter-medium mt-3 "
					variant="primary"
				>
					Next Step
				</Button>
			</div>

			{/* Step 3: Message and Accept Policy */}
			<div style={currentStep === 3 ? {} : hiddenStyle}>
				<Row>
					<Col lg={12}>
						<Form.Group className="mb-3" controlId="message">
							<Form.Label className="montserrat-bold inter-bold">
								Write your message
							</Form.Label>
							<Form.Control
								as="textarea"
								name="message"
								rows={4}
								value={formData.message}
								onChange={handleInputChange}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Row>
					<Col>
						<div className="">
							<Form.Check
								inline
								label={
									<span className="inter-regular" style={{ fontSize: "12px" }}>
										I agree to the{" "}
										<a
											href={"/privacy"}
											target="_blank"
											rel="noopener noreferrer"
											className="px-1"
										>
											Privacy Notice
										</a>{" "}
										of TheLawPartner.coach
									</span>
								}
								name="acceptPolicy"
								checked={formData.acceptPolicy}
								onChange={handleInputChange}
								type={"checkbox"}
								required
							/>
							{formErrors.acceptPolicy && (
								<p style={errorTextStyle}>{formErrors.acceptPolicy}</p>
							)}
						</div>
					</Col>
				</Row>

				{/* Hidden field for gclid */}
				<Form.Group controlId="gclid" className="d-none">
					<Form.Control type="hidden" name="gclid" value={gclid} />
				</Form.Group>
				<Form.Group controlId="landingPage" className="d-none">
					<Form.Control type="hidden" name="landingPage" value={landingPage} />
				</Form.Group>

				<Button
					className="px-4 py-2 w-100 mt-3  inter-medium "
					variant="primary"
					type="submit"
					disabled={state.submitting}
				>
					Submit Form
				</Button>
			</div>
		</Form>
	);
}

export default LandingHeroForm;
