import { Link } from "gatsby";
import React from "react";
import { Button, Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import BrandLogoBlue from "../images/brand-logo.svg";
import BrandLogoOther from "../images/brand-logo-blue.svg";
import { useLocation } from "@reach/router"; // Import useLocation from @reach/router

const NavLanding = () => {
	const location = useLocation();
	const currentPath = location.pathname;
	const bgColour = "";

	const isActive = (path) => currentPath === path;

	const isDropdownActive = (paths) => paths.includes(currentPath);

	return (
		<div>
			<div>
				<Navbar
					style={{ zIndex: 2 }}
					className="position-relative navbar-home"
					bg={bgColour}
					expand="lg"
				>
					<div
						style={{ zIndex: -999 }}
						className="position-absolute nav-bg w-100 h-100"
					></div>
					<Container className="text-center">
						<Navbar.Brand className="mx-auto" >
							<BrandLogoBlue style={{ width: "120px" }} />
						</Navbar.Brand>
					</Container>
				</Navbar>
			</div>
		</div>
	);
};

export default NavLanding;
